import {createRouter, createWebHistory} from "vue-router";

import store from "@/store";

import Login from "@/view/Login.vue";
import Admin from "@/view/Admin.vue";
import Parser from "@/view/Parser.vue";
import Users from "@/view/Users.vue";
import User from "@/view/User.vue";
import MergingAd from "@/view/ad/MergingAd.vue";
import HidingAd from "@/view/ad/HidingAd.vue";
import axios from "axios";
import Ad from "@/view/ad/Ad.vue";
import AdGroups from "@/view/ad/AdGroups.vue";
import RemoveDuplicates from "@/view/ad/RemoveDuplicates.vue";
import Catalog from "@/view/Catalog/Catalog.vue";
import CatalogYacht from "@/view/Catalog/CatalogYacht.vue";
import CatalogYachtEdit from "@/view/Catalog/CatalogYachtEdit.vue";
import Currency from "@/view/Currency.vue";

const pageCatalogRoute = [
    {
        path: '',
        name: 'first-page',
        component: Catalog
    },
    {
        path: 'page/:page',
        name: 'paginationRoute',
        component: Catalog,
        props: true
    }
]

const filterCatalogRoute = [
    {
        path: 'q/:searchName',
        name: 'searchName',
        component: Catalog,
        props: true,
        children: [
            ...pageCatalogRoute
        ]
    }
]

const routes = [
    {
        path: '/',
        name: 'main-menu',
        component: Admin
    },
    {
        path: '/login',
        name: 'login-page',
        component: Login
    },
    {
        path: '/parser',
        name: 'parser-page',
        component: Parser
    },
    {
        path: '/users',
        name: 'users-page',
        component: Users
    },
    {
        path: "/users/:id",
        name: "user-details",
        component: User
    },
    {
        path: "/ad/merging",
        name: "merging-ad",
        component: MergingAd
    },
    {
        path: "/ad/groups",
        name: "ad-groups",
        component: AdGroups
    },
    {
        path: "/ad/remove/duplicates",
        name: "ad-remove-duplicates",
        component: RemoveDuplicates
    },
    {
        path: "/ad/hiding",
        name: "hiding-ad",
        component: HidingAd
    },
    {
        path: "/ad/hiding/:id",
        name: "ad-details",
        component: Ad
    },
    {
        path: "/catalog",
        name: "Catalog",
        alias: "/catalog",
        component: Catalog,
        children: [
            ...filterCatalogRoute,
            ...pageCatalogRoute
        ]
    },
    {
        path: "/catalog/yacht/:id",
        name: "yacht-from-catalog",
        component: CatalogYacht
    },
    {
        path: "/catalog/edit/yacht/:id",
        name: "edit-yacht-from-catalog",
        component: CatalogYachtEdit
    }
    ,
    {
        path: "/currency",
        name: "currency",
        component: Currency
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, from, next) => {
    if (!store.state.auth) {
        await axios.get('auth/user')
            .then(response => {
                if (response.status !== undefined) {
                    if (response.data.role === 'ADMIN') {
                        store.dispatch('setAuth', true);
                    }
                }
            })
    }
    if (!store.state.auth && to.path !== '/login') {
        next('/login');
    } else if (store.state.auth && to.path === '/login') {
        next('/');
    } else {
        next();
    }
});

export default router;